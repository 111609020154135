import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import storage from "@/utils/customLocalStorage";
import auth from "@/utils/auth";
let user = storage.read("user");
let systemUser = storage.read("systemuser");
let loginUser = storage.read("loginuser");
let userShopInfo = storage.read("usershop");
let token = auth.getToken("token");
token = token ? token : "";
if (user) {
    user = JSON.parse(user);
}
if (systemUser) {
    systemUser = JSON.parse(systemUser);
}
if (loginUser) {
    loginUser = JSON.parse(loginUser);
}
if (userShopInfo) {
    userShopInfo = JSON.parse(userShopInfo);
}
export default new Vuex.Store({
    state: {
        navIndex: 0,
        user: user,
        systemUser: systemUser,
        loginUser: loginUser,
        userShopInfo: userShopInfo,
        token: token,
        centerNavIndex: 0,
        isSupplier: false
    },
    mutations: {
        setNavIndex(state, data) {
            state.navIndex = data;
        },
        setUser(state, data) {
            state.user = data;
        },
        setSystemUser(state, data) {
            state.systemUser = data;
        },
        setLoginUser(state, data) {
            state.loginUser = data;
        },
        setUserShopInfo(state, data) {
            state.userShopInfo = data;
        },
        setToken(state, data) {
            state.token = data;
        },
        setCenterNavIndex(state, data) {
            state.centerNavIndex = data;
        },
        setIsSupplier(state, data) {
            state.isSupplier = data;
        }
    },
    actions: {
        acNavIndex({ commit }, data) {
            commit('setNavIndex', data);
        },
        acUser({ commit }, data) {
            commit('setUser', data)
        },
        acSystemUser({ commit }, data) {
            commit('setSystemUser', data);
        },
        acLoginUser({ commit }, data) {
            commit('setLoginUser', data);
        },
        acUserShopInfo({ commit }, data) {
            commit('setUserShopInfo', data);
        },
        acToken({ commit }, data) {
            commit('setToken', data)
        },
        acCenterNavIndex({ commit }, data) {
            commit('setCenterNavIndex', data);
        },
        acIsSupplier({ commit }, data) {
            commit('setIsSupplier', data);
        }
    },
    modules: {}
})