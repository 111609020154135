import { Crypto } from './crypto-min.js'
import axios from 'axios'

const Base64 = require('js-base64').Base64
const config = { headers: { 'Accept': 'application/json, text/javascript, */*; q=0.01' } }
const policyText = {
    'expiration': '2099-01-01T12:00:00.000Z', // 设置该Policy的失效时间，超过这个失效时间之后，就没有办法通过这个policy上传文件了
    'conditions': [
        ['content-length-range', 0, 1048576000] // 设置上传文件的大小限制
    ]
}

export function upload(data, file, isbase64 = false) {
    const policyBase64 = Base64.encode(JSON.stringify(policyText))
    const bytes = Crypto.HMAC(Crypto.SHA1, policyBase64, data.accesskey, { asBytes: true })
    const signature = Crypto.util.bytesToBase64(bytes)

    const multipart_params = formData({
        'key': `${data.key + file.name}`,
        'policy': policyBase64,
        'success_action_status': 200,
        'OSSAccessKeyId': data.OSSAccessKeyId,
        'signature': signature,
        'file': isbase64 ? file : file.raw
    })

    return axios.post('https://global-application-001.downline.cn', multipart_params, config).then(() => {
        return 'https://global-application-001.downline.cn/' + multipart_params.get('key')
    })

    // return http.post('https://global-application-001.downline.cn', multipart_params, config).then(() => {
    //     return process.env.VUE_APP_OSS_URL + '/' + multipart_params.get('key')
    // })
}

/** 对象转换为formData类型 */
function formData(obj) {
    if (obj instanceof Object) {
        const multipart_params = new FormData()
        for (const key in obj) {
            multipart_params.append(key, obj[key])
        }
        return multipart_params
    }
}

/** 生成随机文件名 格式为随机6位数+时间戳+文件类型 */
function getFileName(filename) {
    const pos = filename.lastIndexOf('.')
    console.log(pos)
    if (pos !== -1) {
        return randomString(6) + new Date().getTime() + filename.substring(pos - 1)
    }
    return ''
}

/** 随机数 未指定长度则为32位 */
function randomString(len) {
    len = len || 32
    const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
    const maxPos = chars.length
    let pwd = ''
    for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return pwd
}