import Crypto from '@/utils/crypto';
import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
    let strtoken = Cookies.get(TokenKey);
    if (strtoken) {
        return Crypto.decrypt(strtoken)
    }
    return ""

}

export function setToken(token) {
    let strtoken = Crypto.encrypt(token);
    return Cookies.set(TokenKey, strtoken)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}
export default {
    getToken,
    setToken,
    removeToken
}
