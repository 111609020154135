import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import store from '../store';
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location) {
    return originalPush.call(this, location).catch(err => { err; })
}
import storage from "@/utils/customLocalStorage";
import auth from "@/utils/auth";
const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },

]

const router = new VueRouter({
        routes
    })
    // 设置路由守卫，在进页面之前，判断有token，才进入页面，否则返回登录页面
let token = auth.getToken();
token = "1111111111111"
if (token) {
    store.dispatch("acToken", token)
}
router.beforeEach((to, from, next) => {
    // 判断要去的路由有没有requiresAuth
    if (to.matched.some(r => r.meta.requireAuth)) {
        if (store.state.token) {
            next(); //有token,进行request请求，后台还会验证token
        } else {
            next({
                path: "/login",
            });
        }
    } else {
        next();
    }
});

export default router
