import Crypto from '@/utils/crypto';
const keyprefix = "dzkweb-";
/*
 * 写入
 * @expire 时间戳(毫秒)
 */
const set=(key, value, expire)=> {
    key = `${keyprefix}${key.toString()}`;
    localStorage.setItem(key, Crypto.encrypt({
        value: value,
        time: +new Date,
        expire: expire
    }));
}

/*
 * 读取
 * @return isExpired ==> 是否过期
 */
const read=(key)=> {
    key = `${keyprefix}${key.toString()}`;
    let data = localStorage.getItem(key);
    if (data) {
        data = Crypto.decrypt(data);
        if (data.expire && (data.expire <= new Date().getTime() - data.time)) {
            localStorage.removeItem(key.toString());
            return null;
        } else {
            return data.value;
        };
    };
    return null;
}

/*
 * 删除
 * @key 不传则删除所有
 */
const clear=(key)=> {
    if (key) {
        key = `${keyprefix}${key.toString()}`;
        localStorage.removeItem(key);
    } else {
        localStorage.clear();
    };
}


export default {
    set,read,clear
};
