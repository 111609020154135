<template>
<div class="big_box">
  <!--头部-->
  <div class="top">
    <div class="box">
      <div class="logo"><img src="../images/logo.png" alt=""></div>
      <div class="right_list">
      <ul>
        <li>
        <div class="dialog_button" >
          <el-button type="text" @click="dialogVisible = true" style="color: #828282;">
            <i class="el-icon-chat-dot-square" style="font-size: 35px; display: block;margin-bottom: 12px;"></i>
            <div class="button_title"> 联系我们</div>
          </el-button>
        </div>
      </li>


        <li>

          <div >
            <el-button type="text" @click="dialogVisible2 = true" style="color: #828282;" >
              <i class="el-icon-collection-tag" style="font-size: 35px; display: block;margin-bottom: 12px;"></i>
              <div class="button_title"> 招募人才</div>
            </el-button>
          </div>
        </li>


        <li>
          <div >
            <el-button type="text" @click="dialogVisible3 = true" style="color: #828282;">
              <i class="el-icon-info" style="font-size: 35px; display: block;margin-bottom: 12px;"></i>
              <div class="button_title">关于我们</div>
            </el-button>
          </div>
        </li>

      </ul>
      </div>
    </div>
    <el-dialog
            title="联系我们"
            :visible.sync="dialogVisible"
            width=500px
            :modal-append-to-body="false">
      <div class="dialog_1">

        <div class="connect_head">
          <ul>
            <li>  <span class="connect_head_title">公司名称</span>  <span class="connect_head_title2">上海竖排网络科技有限公司</span></li>
            <li>  <span class="connect_head_title">公司地址</span>  <span class="connect_head_title2">上海市嘉定区瑞林路1280弄湖畔天下48号</span></li>
            <li>  <span class="connect_head_title">联系人</span>  <span class="connect_head_title2">赵小姐</span></li>
            <li>  <span class="connect_head_title">电话</span>  <span class="connect_head_title2">400-920-0560</span></li>
            <li>  <span class="connect_head_title">手机</span>  <span class="connect_head_title2">暂无</span></li>
            <li>  <span class="connect_head_title">QQ</span>  <span class="connect_head_title2">26762685</span></li>
            <li>  <span class="connect_head_title">微信</span>  <span class="connect_head_title2">ciciyue009</span></li>
            <li>  <span class="connect_head_title">邮箱</span>  <span class="connect_head_title2">zhaoyuehong@jinglintu.com</span></li>

            <!--<li style="border:none;"><span ><i ></i>公司名称</span>上海竖排网络科技有限公司</li>
            <li><span  ><i ></i>公司地址</span>上海市嘉定区瑞林路1280弄湖畔天下48号</li>
            <li><span  ><i ></i>联系人</span>赵小姐</li>
            <li><span  ><i ></i>电话</span>400-920-0560</li>
            <li><span  ><i ></i>手机</span>暂无</li>
            <li><span ><i ></i>QQ</span>26762685</li>
            <li><span  ><i ></i>微信</span>ciciyue009</li>
            <li><span  ><i ></i>邮箱</span>zhaoyuehong@jinglintu.com</li>-->

          </ul>
        </div>
        <div class="connect_body">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
                  <!--<el-button @click="dialogVisible = false">取 消</el-button>
                  <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
              </span>
    </el-dialog>

    <el-dialog
            title="招募人才"
            :visible.sync="dialogVisible2"
            width=800px
            :modal-append-to-body="false">
      <div class="dialog_1">
        <div class="shanghuaxian"></div>
        <h3 class="biaoti"> <i class="el-icon-right"></i> 内勤主管</h3>
        <h4 class="fubiaoti">职责范围</h4>
        <div class="xiahuaxian"></div>
        <p class="neiqinzhuguan">1. 管理日常快递订单签收分发；安排快递员工作</p>
        <p class="neiqinzhuguan">2. 按照经营规划，完成周、月、季度计划</p>
        <p class="neiqinzhuguan">3. 负责撰写有关品牌及产品形象的文字介绍，挖掘产品亮点与卖点，提升产品展现力及销售力；</p>
        <p class="neiqinzhuguan">4. 负责微信平台、线下推广活动软文的撰写，利用微信、论坛、社区及各种营销策略迅速提升品牌知名度；</p>
        <p class="neiqinzhuguan">5. 负责市场活动政策、品牌知识等各种培训工作；</p>
        <p class="neiqinzhuguan">6. 市场部团队建设、部门员工管理；</p>
        <p class="neiqinzhuguan">7. 组织市场调研，收集分析生鲜O2O行业信息、竞争对手信息，并提出建设性方案；</p>
        <p class="neiqinzhuguan">8. 领导交办的其它任务。</p>

        <h4 class="fubiaoti">招聘要求</h4>
        <div class="xiahuaxian"></div>
        <p class="neiqinzhuguan">1. 本科及以上学历，电子商务、市场营销、企业管理等相关专业，5年以上市场、策划、文案、销售等相关工作经验，至少1年以上团队管理岗位工作经验</p>
        <p class="neiqinzhuguan">2. 良好的市场运营经验，包括线上线下活动策划、市场运营、产品营销经验（有生鲜品类市场运营经验工作者优先）；</p>
        <p class="neiqinzhuguan">3. 扎实的文字功底和创意能力，熟练使用word、excel、powerpoint等办公软件等；</p>
        <p class="neiqinzhuguan">4. 有很强的创造能力、拓展能力、思维能力与项目管理能力、数据分析能力、敏锐的市场洞察力及危机处理能力；</p>
        <p class="neiqinzhuguan">5. 有较强的数据分析能力，能够独立完成营销活动的策划制定、组织执行、评估改进，能够独立操作谈判项目；</p>
        <p class="neiqinzhuguan">6. 有良好的主动性和责任感，有较强的团队领导力、执行力、组织协调能力、人际交往能力，积极乐观，能承受一定工作强度和压力。</p>

        <h4 class="fubiaoti">招聘待遇</h4>
        <div class="xiahuaxian"></div>
        <p class="neiqinzhuguan">7-12K</p>
        <p class="neiqinzhuguan">包中餐</p>
        <p class="neiqinzhuguan">安排住宿</p>

        <h3 class="biaoti"> <i class="el-icon-right"></i>快递员</h3>
        <h4 class="fubiaoti">职责范围</h4>
        <div class="xiahuaxian"></div>
        <p class="neiqinzhuguan">1. 负责区域内的物品送达及货款的及时返回；</p>
        <p class="neiqinzhuguan">2. 执行业务操作流程，准时送达物品，为客户提供公司相应的增值服务；</p>
        <p class="neiqinzhuguan">3. 整理并呈递相关业务单据和资料；</p>
        <p class="neiqinzhuguan">4. 客户的维护，客户咨询的处理和意见的反馈；</p>
        <p class="neiqinzhuguan">5. 突发事件的处理。</p>

        <h4 class="fubiaoti">招聘要求</h4>
        <div class="xiahuaxian"></div>
        <p class="neiqinzhuguan">1. 大专以上学历，思维敏捷，性格开朗，能吃苦耐劳;</p>
        <p class="neiqinzhuguan">2. 有上进心，责任心强，良好的沟通能力,身体健康；</p>
        <p class="neiqinzhuguan">3. 吃苦耐劳，能够承受一定的工作压力，年龄18-50之间；</p>
      </div>
      <span slot="footer" class="dialog-footer">
                  <!--<el-button @click="dialogVisible = false">取 消</el-button>
                  <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
              </span>
    </el-dialog>

    <el-dialog
            title="关于我们"
            :visible.sync="dialogVisible3"
            width=600px
            :modal-append-to-body="false">
      <div class="dialog_1">
        <span class="yuan"></span> <h3 class="fubiaoti">公司简介</h3>
        <div class="xiahuaxian"></div>
        <p class="neiqinzhuguan">Sample平台社区e家服务平台，专注于多元化社区服务的开发与推进，在社区服务类目上不断完善社区服务项目，并以开放开源的方式为居民提供亲民周到的服务。目前平台主要服务功能涉及：社区快递收发、便民工具箱、便民打印、个人家庭住址隐私保护等等，随着平台的不断升级社区服务的项目也会根据居民的需求不断增加，真正做到离居民最近，及居民所及，成为物业的贴心小帮手、居民的小管家。</p>

        <span class="yuan"></span> <h3 class="fubiaoti">收件服务</h3>
        <div class="xiahuaxian"></div>
        <p class="neiqinzhuguan">居民通过微信公共服务号可根据自身的时间安排选择快递配送的时间段，并通过平台一键式签收各快递公司电商平台的包裹，Sample平台将通过线下网点根据居民需求将不同快递公司的快递包裹一次性配送到居民家中，解决居民一对多不确定性收件的麻烦，同时为快递、电商行业解决社区最后一公里配送问题</p>

        <span class="yuan"></span> <h3 class="fubiaoti">寄件服务</h3>
        <div class="xiahuaxian"></div>
        <p class="neiqinzhuguan">通过Sample平台服务号或APP 可根据自身的时间安排选时下单，工作人员将根据您选择的时间、地点、快速公司准时出现，并为您完成所有寄件时必要的验视包装服务。</p>

        <span class="yuan"></span> <h3 class="fubiaoti">承诺</h3>
        <div class="xiahuaxian"></div>
        <p class="neiqinzhuguan">社区快递配送服务，永久向居民免费。</p>



      </div>
      <span slot="footer" class="dialog-footer">
                  <!--<el-button @click="dialogVisible = false">取 消</el-button>
                  <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
              </span>
    </el-dialog>


  </div>

  <!--banner图-->
  <div class="banner">

    <div class="block">
      <!--<span class="demonstration">默认 Hover 指示器触发</span>-->
      <el-carousel height="645px">
        <el-carousel-item v-for="item in images_banner" :key="item.id">
          <div class="banner_box">
          <img :src="item.url" alt="" style="width: 100%; height: 645px;">
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

  </div>

  <!--合作伙伴-->
  <div class="business_partner" style="width: 1200px; height: 320px;">
    <div class="business_partner_hand">
      <img src="../images/corp_title.png" alt="">
    </div>

    <div class="line_one">
      <ul>
        <li><a href="http://www.sf-express.com/cn/sc/" target="_blank">
          <img src="../assets/img/corp1.png" alt="">
        </a></li>
        <li><a href="http://www.ems.com.cn/" target="_blank">
          <img src="../assets/img/corp2.png" alt="">
        </a></li>
        <li><a href="http://www.zto.com/" target="_blank">
          <img src="../assets/img/corp3.png" alt="">
        </a></li>
        <li><a href="http://www.yundaex.com/" target="_blank">
          <img src="../assets/img/corp4.png" alt="">
        </a></li>
        <li style="margin-right: 0;"><a href="http://www.ttkdex.com/" target="_blank">
          <img src="../assets/img/corp5.png" alt="">
        </a></li>

      </ul>
    </div>
    <div class="line_one">
      <ul>
        <li><a href="http://www.yto.net.cn/" target="_blank">
          <img src="../assets/img/corp6.png" alt="">
        </a></li>
        <li><a href="http://www.sto.cn/" target="_blank">
          <img src="../assets/img/corp7.png" alt="">
        </a></li>
        <li><a href="http://www.chinapost.com.cn/" target="_blank">
          <img src="../assets/img/corp8.png" alt="">
        </a></li>
        <li><a href="http://www.uce.cn/" target="_blank">
          <img src="../assets/img/corp9.png" alt="">
        </a></li>
        <li style="margin-right: 0;"><a href="http://www.800best.com/" target="_blank">
          <img src="../assets/img/corp10.png" alt="">
        </a></li>

      </ul>
    </div>

  </div>

  <!--联系客服-->
  <div class="foot">
    <div class="number">
      <ul>
        <li>
          <div class="title">400-920-0560</div>
          <div class="title">客服电话</div>
        </li>
        <li>
          <el-popover
                  placement="top"
                  title=""
                  width="160"
                  trigger="hover"
                   >
            <div class="title">
              <img :src="weixin_code" alt="">
            </div>
            <el-button slot="reference" class="title">智能导购</el-button>
          </el-popover>
          <div class="title">客服微信</div>
        </li>
        <li>
          <div class="title">26762685</div>
          <div class="title">客服QQ</div>
        </li>
      </ul>
    </div>
  </div>

 <!-- 管理端入口-->
  <p class="manage">
    <a href="002_shop_mgr_sample_vue/dist/index.html" target="_blank" style="margin: 0;">门店入口</a>   <span >|</span>
    <a href="003_warehouse_mgr_sample_vue/dist/index.html" target="_blank" style="margin: 0;">供应商入口</a>  <span>|</span>
    <a href="001_center_data_sample_vue/dist/index.html" target="_blank" style="margin: 0;">中心管理入口</a>

  </p>

  <!--底部-->
  <div class="bottom">
    <p class="bottom_1">©2016  保留一切权利, 版权使用问题，请联系客服</p>
    <p class="bottom_2"><a href="http://beian.miit.gov.cn" target="_blank">沪ICP备14017915号-1</a></p>
  </div>

</div>
</template>

<script>
  import weixin_code from '../images/weixin_code.jpg'
  export default {
    name: "Home",
    components: { weixin_code },
    data() {
      return {
        images_banner: [
          { url: require('../assets/img/banner_01.png') ,
            id: 0,
          },
          { url: require('../assets/img/banner_02.png') ,
            id: 1,
          },
          { url: require('../assets/img/banner_03.png') ,
            id: 2,
          },

          { url: require('../assets/img/banner_04.png') ,
            id: 3,
          },
          { url: require('../assets/img/banner_05.png') ,
            id: 4,
          },
        ],
        visible: false,
        dialogVisible: false,
        dialogVisible2: false,
        dialogVisible3: false,
        weixin_code: weixin_code,
      }
    }
  }
</script>



<style scoped lang="less">
  *{
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  .top{
    position: relative;
    height: 100px;
    z-index: 100;
    box-shadow: 0 0 3px #999;
  }

  .box{
    width: 1200px;
    margin: 0 auto;
    text-align: left;
  }

  .logo{
    width: 200px;
    float: left;
    position: relative;
    top: 14px;
    img {
      width: 100%;
    }
  }

  .right_list{
    top: 18px;
    position: relative;
  }

  .right_list li{
    float: right;
    width: 64px;
    height: 64px;
    margin-left: 40px;
  }

  .right_list li:hover{
    color: #54b74c;
  }

  .dialog_button:hover{
    color: #54b74c;
  }

  .banner{
    height: 645px;
  }

  .business_partner{
    padding: 20px;
    margin: 0 auto;
  }

  .business_partner .business_partner_hand img{
    width: 100%;
  }

  .el-carousel__item h3{
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  
  }

  .line_one{
    margin: 0 auto;
  }

  .line_one li{
    float: left;
    width: 224px;
    margin-right: 20px;
    margin-bottom: 20px;
  }


  .line_one li img{
    width: 224px;
    height: 100px;
  }

  .foot .number{
    margin: 0 auto;
    width: 450px;
    height: 80px;
  }

  .foot .number .title{
    color: #a3a3a3;
    font-size: 12px;
    border: none;
  }

  .el-button:hover{
    background: none;
  }
  .el-button{
    padding: 0;
  }

  .foot .number ul li{
    float: left;
    width: 150px;
    height: 80px;
  }

  .manage{
    width: 1200px;
    margin: 0 auto;

  }

  .manage ul{
    margin: 0 auto;
  }

  .manage a,span{
    text-align: center;
    color: #54b74c;
    margin: 10px;
    font-size: 12px;
  }

  .title{
    text-align: center;
  }

  .manage a:hover{
    border-bottom: 1px solid #54b74c;
  }

  .button_title{
    font-size: 16px;
  }

  .bottom_1{
    font-size: 12px;
    margin-top: 10px;
    color: #666;
  }

  .bottom_2{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    color: #666;
  }

  .bottom_2 a{
    color: #666;
  }

  .connect_head li{
    position: relative;
    width: 433px;
    text-align: left;
    height: 50px;
    line-height: 50px;
    border-bottom: solid 1px #e4e4e4;
  }

  .connect_head_title2{
    position: absolute;
    top: -10px;
    right: 0;
    color: #4a4a4a;
    font-size: 16px;
  }

  .connect_head li .connect_head_title{

    color: #9b9b9b;
    font-size: 16px;
  }

  .shanghuaxian{
    border-top: solid 1px #e5e5e5;
    height: 5px;
    width: 760px;
    text-align: center;
  }

  .biaoti{
    color: #39ab30;
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    padding-left: 20px;
  }

  .fubiaoti{
    text-align: left;
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 400;
    padding-left: 25px;
    padding-top: 10px;
  }

  .neiqinzhuguan{
    font-size: 16px;
    text-align: left;
    padding-left: 35px;
    line-height: 35px;
  }

  .xiahuaxian{
    border-bottom: solid 2px #39ab30;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 25px;
    height: 2px;
    width: 50px;
  }

  .yuan{
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background: #39ab30;
  }

  /deep/ .el-carousel__arrow--left {
    left: 120px;
  }
  /deep/ .el-carousel__arrow--right {
    right: 120px;
  }

  /deep/ .el-carousel__arrow{
    width: 50px;
    height: 50px;
  }







</style>

